var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "day-project-block", class: _vm.theme },
    [
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("component-info", { attrs: { component: _vm.component } }),
          _c(
            "span",
            { staticClass: "actual_time" },
            [
              _vm.object.time_estimate
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("milli2duration")(_vm.time_spent)) +
                        " / " +
                        _vm._s(
                          _vm._f("seconds2duration")(_vm.object.time_estimate)
                        ) +
                        " "
                    ),
                    _vm.time_spent > _vm.object.time_estimate * 1000
                      ? _c("i", { staticClass: "el-icon-warning" })
                      : _vm._e(),
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("milli2duration")(_vm.time_spent)) +
                        " "
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _vm.object.notes
        ? _c("text-editor", {
            attrs: {
              content: _vm.object.notes,
              theme: _vm.theme,
              editable: false,
            },
          })
        : _vm._e(),
      _vm.slot_sessions.length
        ? _c(
            "ul",
            { staticClass: "sessions" },
            _vm._l(_vm.slot_sessions, function (session) {
              return _c("session-activity-block", {
                key: session.id,
                ref: "sessions",
                refInFor: true,
                staticClass: "session",
                attrs: {
                  session: session,
                  start: session.start,
                  stop: session.stop,
                  theme: _vm.theme,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }