var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-day-tracker", class: _vm.theme },
    [
      _vm.show_tracking_block
        ? [_c("user-block", { attrs: { user: _vm.user } })]
        : _vm._e(),
      _vm.slots.length || _vm.fake_slots.length
        ? _c(
            "div",
            { staticClass: "day" },
            [
              _vm.slots.length
                ? [
                    _c("hr", { staticStyle: { margin: "0" } }),
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Scheduled projects :"),
                    ]),
                    _vm._l(_vm.slots, function (slot) {
                      return _c("day-project-block", {
                        key: slot.id,
                        ref: "slotRef",
                        refInFor: true,
                        staticClass: "slot-row",
                        attrs: {
                          user: _vm.user,
                          object: slot,
                          sessions: _vm.sessions,
                          theme: _vm.theme,
                        },
                      })
                    }),
                  ]
                : _vm._e(),
              _vm.fake_slots.length
                ? [
                    _vm.slots.length
                      ? [
                          _c("hr"),
                          _c("span", { staticClass: "title" }, [
                            _vm._v("Extra projects :"),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._l(_vm.fake_slots, function (slot) {
                      return _c("day-project-block", {
                        key: slot.id,
                        ref: "fakeSlotRef",
                        refInFor: true,
                        staticClass: "slot-row",
                        attrs: {
                          user: _vm.user,
                          object: slot,
                          sessions: _vm.sessions,
                          theme: _vm.theme,
                        },
                      })
                    }),
                  ]
                : _vm._e(),
              _vm.user == _vm.current_user
                ? [
                    _c("hr", { staticStyle: { "margin-bottom": "20px" } }),
                    _c(
                      "div",
                      { staticClass: "day-summary" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "clipboard_button",
                            attrs: {
                              size: "small",
                              icon: "el-icon-document-copy",
                            },
                            on: { click: _vm.copyToClipboard },
                          },
                          [_vm._v(" Copy summary to clipboard ")]
                        ),
                        _c("span", { staticClass: "time_total" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("seconds2duration")(_vm.time_total)
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }